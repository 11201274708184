import React, { useState } from 'react';
import PropTypes from 'prop-types';
import placeholderLogo from '../assets/ipmlogo.png';
import './S3Browser.css';
import { debugError } from '../utils/debug';

function Layout({ user, onSignOut, onShowUsersList, children }) {
  const [isSigningOut, setIsSigningOut] = useState(false);
  
  // Improved sign out function
  const handleSignOut = async () => {
    // Prevent multiple clicks
    if (isSigningOut) return;
    
    try {
      setIsSigningOut(true);
      // Call the onSignOut function passed as prop
      await onSignOut();
      // UI redirection or update should be handled by onSignOut
    } catch (error) {
      debugError('Error during sign out:', error);
      // In case of error, reset state to allow another attempt
      setIsSigningOut(false);
      alert('Error during logout. Please try again.');
    }
  };

  // Check if the user is an admin by looking at their Cognito groups
  const isAdmin = user.profile['cognito:groups'] && 
                 user.profile['cognito:groups'].includes('admin');

  return (
    <div className="container">
      <div className="header">
        <img src={placeholderLogo} alt="IPM Logo" className="logo" />
      </div>
      
      <div className="welcome-bar">
        <p>Welcome, {user.profile['cognito:username']} - {user.profile.email}</p>
        <div className="welcome-buttons">
          {/* Show Users List button only for admin users */}
          {isAdmin && onShowUsersList && (
            <button onClick={onShowUsersList} className="users-list-button">
              Users List
            </button>
          )}
          <button 
            className="sign-out-button" 
            onClick={handleSignOut}
            disabled={isSigningOut}
          >
            {isSigningOut ? 'Signing out...' : 'Sign Out'}
          </button>
        </div>
      </div>

      {children}
    </div>
  );
}

Layout.propTypes = {
  user: PropTypes.object.isRequired,
  onSignOut: PropTypes.func.isRequired,
  onShowUsersList: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default Layout; 