// App.js

import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import S3Browser from './components/S3Browser';
import './components/S3Browser.css';
import placeholderLogo from './assets/ipmlogo.png';
import { debug, debugError } from './utils/debug';

function App() {
  const auth = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(() => {
    // Check if we are in logout phase from localStorage
    const logoutState = localStorage.getItem('isLoggingOut');
    return logoutState === 'true';
  });

  useEffect(() => {
    if (auth.error) {
      debugError('Auth error:', auth.error);
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth.user) {
      debug('User data:', auth.user);
      debug('User profile:', auth.user.profile);
      // If we have an authenticated user, remove the logout state
      localStorage.removeItem('isLoggingOut');
      setIsLoggingOut(false);
    }
  }, [auth.user]);

  useEffect(() => {
    debug('Auth state:', {
      isLoading: auth.isLoading,
      isAuthenticated: auth.isAuthenticated,
      activeNavigator: auth.activeNavigator,
      error: auth.error,
      isLoggingOut: isLoggingOut
    });

    // If we are no longer logging out and not authenticated, remove the state
    if (!auth.activeNavigator && !auth.isAuthenticated && isLoggingOut) {
      debug('Logout completed, resetting state');
      localStorage.removeItem('isLoggingOut');
      setIsLoggingOut(false);
      // Redirect to home
      window.location.href = '/';
    }

    // Only if we are not logging out and there is no active navigation
    if (!auth.isLoading && !auth.isAuthenticated && !auth.activeNavigator && !auth.error && !isLoggingOut) {
      debug('Triggering signin redirect...');
      auth.signinRedirect();
    }
  }, [auth.isLoading, auth.isAuthenticated, auth.activeNavigator, auth.error, auth, isLoggingOut]);

  const handleSignOut = async () => {
    debug('Starting logout process...');
    setIsLoggingOut(true);
    localStorage.setItem('isLoggingOut', 'true');
    
    try {
      debug('Initiating signout redirect...');
      await auth.signoutRedirect();
    } catch (error) {
      debugError('Error during logout:', error);
      localStorage.removeItem('isLoggingOut');
      setIsLoggingOut(false);
    }
  };

  if (auth.isLoading) {
    debug('Rendering loading state...');
    return (
      <div className="container">
        <div className="header">
          <img src={placeholderLogo} alt="IPM Logo" className="logo" />
        </div>
        <div className="welcome-bar">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (auth.error) {
    debug('Rendering error state:', auth.error);
    return (
      <div className="container">
        <div className="header">
          <img src={placeholderLogo} alt="IPM Logo" className="logo" />
        </div>
        <div className="welcome-bar">
          <p>Error: {auth.error.message}</p>
        </div>
      </div>
    );
  }

  if (!auth.isAuthenticated && !isLoggingOut) {
    debug('Rendering unauthenticated state...');
    return (
      <div className="container">
        <div className="header">
          <img src={placeholderLogo} alt="IPM Logo" className="logo" />
        </div>
        <div className="welcome-bar">
          <p>Redirecting to login...</p>
        </div>
      </div>
    );
  }

  if (isLoggingOut) {
    debug('Rendering logout state...');
    return (
      <div className="container">
        <div className="header">
          <img src={placeholderLogo} alt="IPM Logo" className="logo" />
        </div>
        <div className="welcome-bar">
          <p>Logging out...</p>
          <button 
            onClick={() => {
              localStorage.removeItem('isLoggingOut');
              setIsLoggingOut(false);
              window.location.href = '/';
            }}
          >
            Cancel Logout
          </button>
        </div>
      </div>
    );
  }

  debug('Rendering S3Browser...');
  return (
    <S3Browser 
      user={{
        profile: {
          'cognito:username': auth.user?.profile.preferred_username || auth.user?.profile.username,
          email: auth.user?.profile.email,
          'cognito:groups': auth.user?.profile['cognito:groups'] || [],
        },
        id_token: auth.user?.id_token
      }}
      onSignOut={handleSignOut}
    />
  );
}

export default App;