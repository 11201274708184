import { useState, useEffect, useRef } from 'react';
import { S3Client, ListObjectsV2Command, GetObjectCommand, GetObjectAttributesCommand, RestoreObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { CognitoIdentityProviderClient, ListUsersCommand, AdminListGroupsForUserCommand, AdminAddUserToGroupCommand, AdminRemoveUserFromGroupCommand, AdminCreateUserCommand } from '@aws-sdk/client-cognito-identity-provider';
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import './S3Browser.css';
import PropTypes from 'prop-types';
import Layout from './Layout';
import { debug } from '../utils/debug';

const checkDebugFileStatus = async (file) => {
  try {
    const s3Client = await getS3Client();
    const command = new GetObjectAttributesCommand({
      Bucket: 'ipm-certificates',
      Key: file.Key,
      ObjectAttributes: [
        'StorageClass'
      ]
    });

    const response = await s3Client.send(command);
    debug('File status check response:', response);
    const storageClass = response.StorageClass;
    const storageClassinfo = getStorageClassInfo(storageClass)
    
    debug('Storage Class:', storageClass)
    debug('Storage Class Info:', storageClassinfo)
  } catch (err) {
    debug('Error checking file status:', err);
    throw err;
  }
};

const handleDebugFileAction = async (key) => {
  try {
      const fileStatus = await checkDebugFileStatus(key);
      debug('File status received:', fileStatus);
  } catch (err) {
      debug('Handle file action error:', err);
  }
};

function Debug({ file }) {
  return (
      <button
          className="debug-button"
          onClick={() => handleDebugFileAction(file.Key)}
      >
          Debug
      </button>
  );
}

Debug.propTypes = {
  file: PropTypes.object.isRequired,
};

function S3Browser({ user, onSignOut }) {
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [folderLoading, setFolderLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const isMounted = useRef(false); // Ref to track if component has already mounted
  
  // Users list state
  const [showUsersList, setShowUsersList] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersError, setUsersError] = useState(null);
  
  // User group editing states
  const [editingUser, setEditingUser] = useState(null);
  const [updatingGroup, setUpdatingGroup] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  // Folder assignment states
  const [assigningFolders, setAssigningFolders] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [availableFolders, setAvailableFolders] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [savingPermissions, setSavingPermissions] = useState(false);
  const [folderAssignmentError, setFolderAssignmentError] = useState(null);

  // New user states
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [creatingUser, setCreatingUser] = useState(false);
  const [createUserError, setCreateUserError] = useState(null);
  const [newUserData, setNewUserData] = useState({
    username: '',
    email: ''
  });

  const getS3Client = async () => {
    try {
      debug('Initializing S3 client...');
      const credentials = fromCognitoIdentityPool({
        clientConfig: { region: process.env.REACT_APP_AWS_REGION },
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        logins: {
          [`cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_COGNITO_USER_POOL_ID}`]: user.id_token
        }
      });

      const resolvedCreds = await credentials();
      // Non logghiamo più le credenziali per sicurezza
      
      return new S3Client({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: resolvedCreds
      });
    } catch (err) {
      debug('Error initializing S3 client');
      throw err;
    }
  };

  const getUserPermissions = async (s3Client, username) => {
    try {
      const userGroups = user.profile['cognito:groups'] || [];
      debug('Checking user permissions...');
      
      if (userGroups.includes('admin')) {
        debug('User has admin role');
        return { buckets: [], role: 'admin' };
      }
      
      if (userGroups.includes('owner')) {
        debug('User has owner role');
      } else if (userGroups.includes('users')) {
        debug('User has user role');
      }
      
      const command = new GetObjectCommand({
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Key: `users/${username}.json`
      });
      
      try {
        const response = await s3Client.send(command);
        const permissionsData = await response.Body.transformToString();
        const permissions = JSON.parse(permissionsData);
        
        if (userGroups.includes('owner') && permissions.role !== 'owner') {
          debug('Correcting role mismatch for owner');
          permissions.role = 'owner';
        } else if (userGroups.includes('users') && permissions.role !== 'user') {
          debug('Correcting role mismatch for user');
          permissions.role = 'user';
        }
        
        return permissions;
      } catch (err) {
        if (err.name === 'NoSuchKey') {
          debug('No permissions file found');
          if (userGroups.includes('owner')) {
            return { buckets: [], role: 'owner' };
          } else if (userGroups.includes('users')) {
            return { buckets: [], role: 'user' };
          }
          return { buckets: [], role: 'unknown' };
        }
        throw err;
      }
    } catch (err) {
      debug('Error in permission check');
      throw new Error('Failed to retrieve your access permissions');
    }
  };

  const fetchFolderContents = async (folderName) => {
    try {
      setFolderLoading(true);
      const s3Client = await getS3Client();
      
      const command = new ListObjectsV2Command({
        Bucket: process.env.REACT_APP_S3_BUCKET,
        Prefix: `${folderName}/`
      });
      
      const response = await s3Client.send(command);
      const folderFiles = (response.Contents || [])
        .filter(file => !file.Key.endsWith('/'))
        .map(file => ({
          ...file,
          displayName: file.Key.replace(`${folderName}/`, ''),
          folderName: folderName
        }));
      
      setFiles(folderFiles);
      setFolderLoading(false);
    } catch (err) {
      debug('Error loading folder contents');
      setError(`Error loading folder: ${err.message}`);
      setFolderLoading(false);
    }
  };

  const selectFolder = (folder) => {
    setSelectedFolder(folder);
    fetchFolderContents(folder);
  };

  const goBack = () => {
    setSelectedFolder(null);
    setFiles([]);
  };

  useEffect(() => {
    // Prevent double calls in development mode (React StrictMode causes this)
    if (isMounted.current) {
      return;
    }
    
    isMounted.current = true;
    
    const fetchFolders = async () => {
      try {
        debug('User profile:', user.profile);
        const s3Client = await getS3Client();
        const username = user.profile['cognito:username'];
        debug('Username:', username);
        
        // Get user permissions
        const permissions = await getUserPermissions(s3Client, username);
        setUserPermissions(permissions);
        debug('User permissions:', permissions);
        
        if (permissions.role === 'admin') {
          // Admin users can see all folders in the bucket
          const command = new ListObjectsV2Command({
            Bucket: 'ipm-certificates',
            Delimiter: '/'
          });
          
          const response = await s3Client.send(command);
          // Get top-level folders
          const availableFolders = (response.CommonPrefixes || [])
            .map(prefix => prefix.Prefix.replace('/', ''))
            .filter(folder => folder !== 'users'); // Exclude users folder
          
          debug('Available folders for admin:', availableFolders);
          setFolders(availableFolders);
        } else if (permissions.role === 'owner' || permissions.role === 'user') {
          // Owners and regular users can only see their allowed folders
          setFolders(permissions.buckets || []);
        } else {
          // Unknown role - no folders available
          setFolders([]);
        }
        
        setLoading(false);
      } catch (err) {
        debug('Full error:', err);
        setError(`${err.message} (${err.code})`);
        setLoading(false);
      }
    };

    if (user?.id_token) {
      debug('Starting fetchFolders with user:', {
        username: user.profile['cognito:username'],
        email: user.profile.email
      });
      fetchFolders();
    }
  }, [user]);

  const getStorageClassInfo = (storageClass) => {
    switch (storageClass) {
      case 'DEEP_ARCHIVE':
        return {
          tier: 'Standard',
          retrievalTime: '12 hours',
          days: 7
        };
      case 'GLACIER':
        return {
          tier: 'Standard',
          retrievalTime: '3-5 hours',
          days: 7
        };
      case 'STANDARD':
      case 'STANDARD_IA':
      default:
        return {
          status: 'available',
          message: null
        };
    }
  };

  const initiateRestore = async (key, storageClass) => {
    try {
      debug('Initiating restore for:', key, 'Storage class:', storageClass);
      const info = getStorageClassInfo(storageClass);
      const s3Client = await getS3Client();
      const command = new RestoreObjectCommand({
        Bucket: 'ipm-certificates',
        Key: key,
        RestoreRequest: {
          Days: info.days,
          GlacierJobParameters: {
            Tier: info.tier
          }
        }
      });

      debug('Sending restore command:', command);
      await s3Client.send(command);
      return {
        status: 'restoring',
        message: 'Restore process initiated. The file will be available in ${info.retrievalTime} and will remain accessible for ${info.days} days.'
      };
    } catch (err) {
      debug('Error initiating restore:', err);
      if (err.name === 'TypeError' && err.message === 'Failed to fetch') {
        throw new Error('Unable to initiate restore. Please check your connection or try again later.');
      }
      if (err.name === 'RestoreAlreadyInProgress') {
        return {
          status: 'restoring',
          message: 'A restore process is already in progress for this file. Please wait for it to complete.'
        };
      }
      throw err;
    }
  };

  const checkFileStatus = async (key) => {
    try {
      const s3Client = await getS3Client();
      const command = new GetObjectAttributesCommand({
        Bucket: 'ipm-certificates',
        Key: key,
        ObjectAttributes: [
          'StorageClass'
        ]
      });

      const response = await s3Client.send(command);
      debug('File status check response:', response);

      // Check for Glacier or Deep Archive storage classes first
      if (['GLACIER', 'DEEP_ARCHIVE'].includes(response.StorageClass)) {
        debug('File is in Glacier storage:', response.StorageClass);
        // If no restore status or restore is not in progress
        if (!response.Restore) {
          const info = getStorageClassInfo(response.StorageClass);
          return {
            status: 'archived',
            storageClass: response.StorageClass,
            message: `This file is archived. Click to start the retrieval process (will take ${info.retrievalTime}).`
          };
        }
        
        // Check restore status if present
        if (response.Restore) {
          if (response.Restore.includes('ongoing-request="true"')) {
            const info = getStorageClassInfo(response.StorageClass);
            return {
              status: 'restoring',
              message: `File is being retrieved from archive. It will be available in ${info.retrievalTime} and will remain accessible for ${info.days} days.`
            };
          } else if (response.Restore.includes('ongoing-request="false"')) {
            return { status: 'available', message: null };
          }
        }
      }

      // For STANDARD and STANDARD_IA storage classes
      if (['STANDARD', 'STANDARD_IA'].includes(response.StorageClass)) {
        return { status: 'available', message: null };
      }

      return { status: 'available', message: null };

    } catch (err) {
      debug('Error checking file status:', err);
      throw err;
    }
  };

  const getDownloadUrl = async (key) => {
    try {
      const fileStatus = await checkFileStatus(key);

      if (fileStatus.status !== 'available') {
        throw new Error(fileStatus.message);
      }

      const s3Client = await getS3Client();
      const command = new GetObjectCommand({
        Bucket: 'ipm-certificates',
        Key: key
      });

      const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
      return url;
    } catch (err) {
      debug('Error generating download URL:', err);
      throw err;
    }
  };

  const handleFileAction = async (key) => {
    try {
      const fileStatus = await checkFileStatus(key);
      debug('File status received:', fileStatus);
      let restoreStatus;
      let url;
      
      switch (fileStatus.status) {
        case 'archived':
          try {
            restoreStatus = await initiateRestore(key, fileStatus.storageClass);
            setMessage(restoreStatus.message);
          } catch (restoreErr) {
            debug('Restore error:', restoreErr);
            setError(restoreErr.message);
          }
          break;
          
        case 'restoring':
          setMessage(fileStatus.message);
          break;
          
        case 'available':
          url = await getDownloadUrl(key);
          window.open(url, '_blank');
          break;
      }
    } catch (err) {
      debug('Handle file action error:', err);
      setError(err.message);
    }
  };

  const getCognitoClient = async () => {
    try {
      debug('Initializing Cognito client...');
      const credentials = fromCognitoIdentityPool({
        clientConfig: { region: 'eu-west-1' },
        identityPoolId: 'eu-west-1:f1bc5a82-20e2-4689-94bb-d82b1c903f58',
        logins: {
          'cognito-idp.eu-west-1.amazonaws.com/eu-west-1_CvL2QiilE': user.id_token
        }
      });

      const resolvedCreds = await credentials();
      debug('Cognito client initialized');

      return new CognitoIdentityProviderClient({
        region: 'eu-west-1',
        credentials: resolvedCreds
      });
    } catch (err) {
      debugError('Error initializing Cognito client');
      debug('Error getting Cognito credentials:', err);
      throw err;
    }
  };

  const fetchUsers = async () => {
    if (!userPermissions || userPermissions.role !== 'admin') {
      return;
    }

    try {
      setLoadingUsers(true);
      setUsersError(null);
      
      const cognitoClient = await getCognitoClient();
      
      // List users in Cognito User Pool
      const listCommand = new ListUsersCommand({
        UserPoolId: 'eu-west-1_CvL2QiilE',
        Limit: 60
      });
      
      const response = await cognitoClient.send(listCommand);
      
      // For each user, get their groups
      const usersWithGroups = await Promise.all(response.Users.map(async (cognitoUser) => {
        try {
          const username = cognitoUser.Username;
          
          // Get user groups
          const groupsCommand = new AdminListGroupsForUserCommand({
            UserPoolId: 'eu-west-1_CvL2QiilE',
            Username: username
          });
          
          const groupsResponse = await cognitoClient.send(groupsCommand);
          const groups = groupsResponse.Groups.map(g => g.GroupName);
          
          // Get user email from attributes
          const emailAttr = cognitoUser.Attributes.find(attr => attr.Name === 'email');
          const email = emailAttr ? emailAttr.Value : 'N/A';
          
          return {
            username,
            email,
            groups,
            status: cognitoUser.UserStatus,
            created: cognitoUser.UserCreateDate
          };
        } catch (err) {
          debug('Error fetching groups for user:', err);
          return {
            username: cognitoUser.Username,
            email: 'Error loading data',
            groups: [],
            status: cognitoUser.UserStatus,
            created: cognitoUser.UserCreateDate
          };
        }
      }));
      
      setUsers(usersWithGroups);
      setLoadingUsers(false);
    } catch (err) {
      debug('Error fetching users:', err);
      setUsersError(`Failed to load users: ${err.message}`);
      setLoadingUsers(false);
    }
  };

  const handleShowUsersList = () => {
    setShowUsersList(true);
    fetchUsers();
  };

  const handleCloseUsersList = () => {
    setShowUsersList(false);
  };

  const handleGroupClick = (username, currentGroups) => {
    if (updatingGroup) return; // Prevent clicking while an update is in progress
    
    setEditingUser({
      username,
      currentGroups: currentGroups || []
    });
  };

  const handleGroupChange = async (newGroup) => {
    if (!editingUser || updatingGroup) return;
    
    try {
      setUpdatingGroup(true);
      setUpdateError(null);
      
      const cognitoClient = await getCognitoClient();
      const { username, currentGroups } = editingUser;
      
      // The possible groups
      const availableGroups = ['admin', 'owner', 'users'];
      
      // Determine which groups to add and remove
      let groupsToRemove = [];
      let groupToAdd = null;
      
      if (newGroup === 'None') {
        // Remove all groups
        groupsToRemove = [...currentGroups];
      } else {
        // Remove existing groups except admin (only admins can use this feature)
        groupsToRemove = currentGroups.filter(g => g !== 'admin' && g !== newGroup);
        
        // Add the new group if not already present
        if (!currentGroups.includes(newGroup)) {
          groupToAdd = newGroup;
        }
      }
      
      // Process removals first
      for (const groupName of groupsToRemove) {
        const removeCommand = new AdminRemoveUserFromGroupCommand({
          UserPoolId: 'eu-west-1_CvL2QiilE',
          Username: username,
          GroupName: groupName
        });
        
        await cognitoClient.send(removeCommand);
        debug(`Removed user ${username} from group ${groupName}`);
      }
      
      // Then add to new group if needed
      if (groupToAdd) {
        const addCommand = new AdminAddUserToGroupCommand({
          UserPoolId: 'eu-west-1_CvL2QiilE',
          Username: username,
          GroupName: groupToAdd
        });
        
        await cognitoClient.send(addCommand);
        debug(`Added user ${username} to group ${groupToAdd}`);
      }
      
      // Refresh the users list
      await fetchUsers();
      
      // Reset editing state
      setEditingUser(null);
      setUpdatingGroup(false);
    } catch (err) {
      debug('Error updating user group:', err);
      setUpdateError(`Failed to update group: ${err.message}`);
      setUpdatingGroup(false);
    }
  };

  const cancelGroupEdit = () => {
    setEditingUser(null);
    setUpdateError(null);
  };

  // Fetch all available folders in the bucket
  const fetchAllFolders = async () => {
    try {
      setLoadingFolders(true);
      const s3Client = await getS3Client();
      
      const command = new ListObjectsV2Command({
        Bucket: 'ipm-certificates',
        Delimiter: '/'
      });
      
      const response = await s3Client.send(command);
      // Get top-level folders
      const folders = (response.CommonPrefixes || [])
        .map(prefix => prefix.Prefix.replace('/', ''))
        .filter(folder => folder !== 'users'); // Exclude users folder
      
      setAvailableFolders(folders);
      setLoadingFolders(false);
    } catch (err) {
      debug('Error fetching all folders:', err);
      setFolderAssignmentError(`Error loading folders: ${err.message}`);
      setLoadingFolders(false);
    }
  };

  // Fetch current user permissions
  const fetchUserPermissions = async (username) => {
    try {
      setLoadingFolders(true);
      const s3Client = await getS3Client();
      
      try {
        const command = new GetObjectCommand({
          Bucket: 'ipm-certificates',
          Key: `users/${username}.json`
        });
        
        const response = await s3Client.send(command);
        const permissionsData = await response.Body.transformToString();
        const permissions = JSON.parse(permissionsData);
        
        setSelectedFolders(permissions.buckets || []);
      } catch (err) {
        if (err.name === 'NoSuchKey') {
          // No permissions file exists yet
          setSelectedFolders([]);
        } else {
          throw err;
        }
      }
      
      setLoadingFolders(false);
    } catch (err) {
      debug('Error fetching user permissions:', err);
      setFolderAssignmentError(`Error loading user permissions: ${err.message}`);
      setLoadingFolders(false);
    }
  };

  // Save user permissions to S3
  const saveUserPermissions = async () => {
    if (!selectedUser) return;
    
    try {
      setSavingPermissions(true);
      setFolderAssignmentError(null);
      
      const s3Client = await getS3Client();
      
      // Determine role based on user's groups
      const role = selectedUser.groups.includes('owner') 
        ? 'owner' 
        : selectedUser.groups.includes('users') 
          ? 'user' 
          : 'unknown';
      
      // Create permissions object
      const permissions = {
        role: role,
        buckets: selectedFolders,
        lastUpdated: new Date().toISOString(),
        updatedBy: user.profile['cognito:username']
      };
      
      // Convert to JSON
      const permissionsJson = JSON.stringify(permissions, null, 2);
      
      // Save to S3
      const command = new PutObjectCommand({
        Bucket: 'ipm-certificates',
        Key: `users/${selectedUser.username}.json`,
        Body: permissionsJson,
        ContentType: 'application/json'
      });
      
      await s3Client.send(command);
      debug(`Saved permissions for user ${selectedUser.username}`);
      
      // Close modal and reset state
      setAssigningFolders(false);
      setSelectedUser(null);
      setSelectedFolders([]);
      setSavingPermissions(false);
    } catch (err) {
      debug('Error saving user permissions:', err);
      setFolderAssignmentError(`Error saving permissions: ${err.message}`);
      setSavingPermissions(false);
    }
  };

  // Handle folder checkbox changes
  const handleFolderCheckboxChange = (folder) => {
    setSelectedFolders(prev => {
      if (prev.includes(folder)) {
        return prev.filter(f => f !== folder);
      } else {
        return [...prev, folder];
      }
    });
  };

  // Open the folder assignment modal
  const handleAssignFolders = (userData) => {
    setSelectedUser(userData);
    setAssigningFolders(true);
    setFolderAssignmentError(null);
    fetchAllFolders();
    fetchUserPermissions(userData.username);
  };

  // Close the folder assignment modal
  const handleCloseAssignFolders = () => {
    setAssigningFolders(false);
    setSelectedUser(null);
    setSelectedFolders([]);
    setFolderAssignmentError(null);
  };

  // Handle user creation
  const handleCreateUser = async (e) => {
    e.preventDefault();
    
    try {
      setCreatingUser(true);
      setCreateUserError(null);
      
      const cognitoClient = await getCognitoClient();
      
      // Crea l'utente in Cognito
      const command = new AdminCreateUserCommand({
        UserPoolId: 'eu-west-1_CvL2QiilE',
        Username: newUserData.username,
        UserAttributes: [
          {
            Name: 'email',
            Value: newUserData.email
          },
          {
            Name: 'email_verified',
            Value: 'true'
          }
        ],
        DesiredDeliveryMediums: ['EMAIL'], // Invia l'invito via email
        ForceAliasCreation: false
      });
      
      const response = await cognitoClient.send(command);
      
      // Aggiungi l'utente al gruppo 'users' di default
      const addToGroupCommand = new AdminAddUserToGroupCommand({
        UserPoolId: 'eu-west-1_CvL2QiilE',
        Username: newUserData.username,
        GroupName: 'users'
      });
      
      await cognitoClient.send(addToGroupCommand);
      
      // Resetta il form e chiudi il modale
      setNewUserData({ username: '', email: '' });
      setShowCreateUser(false);
      
      // Aggiorna la lista degli utenti
      await fetchUsers();
      
      setCreatingUser(false);
    } catch (err) {
      debug('Error creating user:', err);
      setCreateUserError(`Failed to create user: ${err.message}`);
      setCreatingUser(false);
    }
  };

  if (loading) return (
    <Layout user={user} onSignOut={onSignOut}>
      <div className="files-container">
        <p>Loading...</p>
      </div>
    </Layout>
  );

  if (error && !selectedFolder) return (
    <Layout user={user} onSignOut={onSignOut}>
      <div className="files-container">
        <p>Error: {error}</p>
      </div>
    </Layout>
  );

  return (
    <Layout 
      user={user} 
      onSignOut={onSignOut} 
      onShowUsersList={handleShowUsersList}
    >
      <div className="files-container">
        <h2>Your Certificates</h2>
        {error && <div className="error-message">{error}</div>}
        {message && <div className="info-message">{message}</div>}
        {userPermissions && process.env.NODE_ENV === 'development' && (
          <div className="user-role">
            Role: {userPermissions.role}
            {userPermissions.buckets && userPermissions.buckets.length > 0 && (
              <span> | Accessible folders: {userPermissions.buckets.join(', ')}</span>
            )}
          </div>
        )}

        {!selectedFolder ? (
          // Show folders view
          <>
            <h3>Available Folders</h3>
            {folders.length === 0 ? (
              <p>No folders available for your account.</p>
            ) : (
              <ul className="folders-list">
                {folders.map((folder) => (
                  <li key={folder} className="folder-item">
                    <a 
                      href="#"
                      className="folder-link"
                      onClick={(e) => {
                        e.preventDefault();
                        selectFolder(folder);
                      }}
                    >
                      <span className="folder-icon">📁</span> {folder}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </>
        ) : (
          // Show selected folder content
          <>
            <div className="folder-navigation">
              <button className="back-button" onClick={goBack}>
                ← Back to Folders
              </button>
              <h3>Folder: {selectedFolder}</h3>
            </div>
            
            {folderLoading ? (
              <p>Loading folder contents...</p>
            ) : (
              <>
                {files.length === 0 ? (
                  <p>No files in this folder.</p>
                ) : (
                  <ul className="files-list">
                    {files.map((file) => (
                      <li key={file.Key} className="file-item">
                        <span>{file.displayName}</span>
                        {process.env.NODE_ENV === 'development' && <Debug file={file} />}
                        <button
                          className="download-button"
                          onClick={() => handleFileAction(file.Key)}
                        >
                          Download
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* Users List Modal */}
      {showUsersList && (
        <div className="modal-overlay">
          <div className="modal-content users-modal">
            <div className="modal-header">
              <h2>Cognito Users List</h2>
              <button className="close-modal" onClick={handleCloseUsersList}>&times;</button>
            </div>
            
            {/* Add New User button */}
            <div className="modal-actions">
              <button 
                className="create-user-button"
                onClick={() => setShowCreateUser(true)}
              >
                New User
              </button>
            </div>

            {/* Create user form */}
            {showCreateUser && (
              <div className="create-user-form">
                <h3>Create New User</h3>
                {createUserError && (
                  <div className="error-message">{createUserError}</div>
                )}
                <form onSubmit={handleCreateUser}>
                  <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                      type="text"
                      id="username"
                      value={newUserData.username}
                      onChange={(e) => setNewUserData(prev => ({
                        ...prev,
                        username: e.target.value
                      }))}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      type="email"
                      id="email"
                      value={newUserData.email}
                      onChange={(e) => setNewUserData(prev => ({
                        ...prev,
                        email: e.target.value
                      }))}
                      required
                    />
                  </div>
                  <div className="form-actions">
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => {
                        setShowCreateUser(false);
                        setNewUserData({ username: '', email: '' });
                        setCreateUserError(null);
                      }}
                      disabled={creatingUser}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="save-button"
                      disabled={creatingUser}
                    >
                      {creatingUser ? 'Creating...' : 'Create User'}
                    </button>
                  </div>
                </form>
              </div>
            )}

            {loadingUsers ? (
              <div className="loading-users">Loading users...</div>
            ) : usersError ? (
              <div className="error-message">{usersError}</div>
            ) : (
              <>
                {updateError && (
                  <div className="error-message">{updateError}</div>
                )}
                
                <table className="users-table">
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Group</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length === 0 ? (
                      <tr>
                        <td colSpan="4">No users found</td>
                      </tr>
                    ) : (
                      users.map(userData => (
                        <tr key={userData.username}>
                          <td>{userData.username}</td>
                          <td>{userData.email}</td>
                          <td>
                            {editingUser && editingUser.username === userData.username ? (
                              <div className="group-selector">
                                <select 
                                  value={editingUser.currentGroups.includes('admin') 
                                    ? 'admin' 
                                    : editingUser.currentGroups.includes('owner') 
                                      ? 'owner' 
                                      : editingUser.currentGroups.includes('users') 
                                        ? 'users' 
                                        : 'None'}
                                  onChange={(e) => handleGroupChange(e.target.value)}
                                  disabled={updatingGroup}
                                >
                                  <option value="None">None</option>
                                  <option value="users">users</option>
                                  <option value="owner">owner</option>
                                  {editingUser.currentGroups.includes('admin') && (
                                    <option value="admin">admin</option>
                                  )}
                                </select>
                                {updatingGroup ? (
                                  <span className="loading-indicator">Updating...</span>
                                ) : (
                                  <button 
                                    className="cancel-edit" 
                                    onClick={cancelGroupEdit}
                                  >
                                    Cancel
                                  </button>
                                )}
                              </div>
                            ) : (
                              <button
                                className={`group-button ${userData.groups.length === 0 ? 'disabled-group' : 'editable-group'}`}
                                onClick={() => handleGroupClick(userData.username, userData.groups)}
                                disabled={userData.username === user.profile['cognito:username']}
                              >
                                {userData.groups.length === 0 ? 'None' : userData.groups.join(', ')}
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              className="assign-folders-button"
                              onClick={() => handleAssignFolders(userData)}
                              disabled={!userData.groups.includes('users')}
                            >
                              Assign Folders
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      )}

      {/* Folder Assignment Modal */}
      {assigningFolders && selectedUser && (
        <div className="modal-overlay">
          <div className="modal-content folders-modal">
            <div className="modal-header">
              <h2>Assign folders to {selectedUser.username}</h2>
              <button className="close-modal" onClick={handleCloseAssignFolders}>&times;</button>
            </div>
            
            {folderAssignmentError && (
              <div className="error-message">{folderAssignmentError}</div>
            )}
            
            <div className="user-info-box">
              <p><strong>Username:</strong> {selectedUser.username}</p>
              <p><strong>Email:</strong> {selectedUser.email}</p>
              <p><strong>Group:</strong> {selectedUser.groups.join(', ') || 'None'}</p>
            </div>
            
            {loadingFolders ? (
              <div className="loading-folders">Loading folders...</div>
            ) : (
              <>
                <div className="folders-selection">
                  <h3>Select accessible folders:</h3>
                  
                  {availableFolders.length === 0 ? (
                    <p>No folders available.</p>
                  ) : (
                    <div className="folders-checkbox-list">
                      {availableFolders.map(folder => (
                        <div key={folder} className="folder-checkbox-item">
                          <label className="folder-checkbox-label">
                            <input
                              type="checkbox"
                              checked={selectedFolders.includes(folder)}
                              onChange={() => handleFolderCheckboxChange(folder)}
                            />
                            <span className="folder-name">{folder}</span>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                
                <div className="modal-actions">
                  <button 
                    className="cancel-button"
                    onClick={handleCloseAssignFolders}
                    disabled={savingPermissions}
                  >
                    Cancel
                  </button>
                  <button 
                    className="save-button"
                    onClick={saveUserPermissions}
                    disabled={savingPermissions}
                  >
                    {savingPermissions ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

S3Browser.propTypes = {
  user: PropTypes.object.isRequired,
  onSignOut: PropTypes.func.isRequired
};

export default S3Browser; 